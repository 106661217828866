import React, { useState } from "react";
import { jsPDF } from "jspdf";

function ImagesPDF() {
    const [selectedImages, setSelectedImages] = useState([]);
    const [draggingIndex, setDraggingIndex] = useState(null);
    const [imagesLoaded, setImagesLoaded] = useState({});

    // Handle file input change
    const handleFileChange = (event) => {
        const files = event.target.files;
        const imageFiles = Array.from(files).filter((file) =>
            file.type.startsWith("image/")
        );

        // Update the state with the selected image files
        setSelectedImages(imageFiles);
        setImagesLoaded({}); // Reset loading state
    };

    // Handle drag start
    const handleDragStart = (index) => {
        setDraggingIndex(index);
    };

    // Handle drag over (allow dropping)
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Handle drop (reorder the images)
    const handleDrop = (event, targetIndex) => {
        event.preventDefault();
        if (draggingIndex === targetIndex) return;

        const updatedImages = [...selectedImages];
        const [draggedImage] = updatedImages.splice(draggingIndex, 1); // Remove the dragged image
        updatedImages.splice(targetIndex, 0, draggedImage); // Insert it at the new position

        setSelectedImages(updatedImages);
        setDraggingIndex(null);
    };

    // Function to download PDF with images in current order
    const downloadPDF = () => {
        const doc = new jsPDF();

        selectedImages.forEach((image, index) => {
            const reader = new FileReader();

            // Load image and then add it to the PDF
            reader.onload = (e) => {
                const imgData = e.target.result;
                const img = new Image();
                img.onload = () => {
                    const imgWidth = doc.internal.pageSize.width;
                    const imgHeight = (img.height * imgWidth) / img.width;

                    if (index > 0) {
                        doc.addPage();
                    }

                    // Add image to PDF
                    doc.addImage(imgData, image.type === 'image/png' ? 'PNG' : 'JPEG', 0, 0, imgWidth, imgHeight);

                    // If last image, save PDF after it's added
                    if (index === selectedImages.length - 1) {
                        doc.save("images.pdf");
                    }
                };
                img.src = imgData; // Set the image source
            };

            // Read the file as a data URL
            reader.readAsDataURL(image);
        });
    };

    return (
        <div className="relative overflow-hidden h-full mt-32 mb-48">
            <div className="md:max-w-4xl xl:max-w-6xl mx-auto">
                <div className="text-start">
                    <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                        Images to PDF
                    </h1>
                    <p className="mt-3 text-gray-600">Create a PDF file from your Images</p>
                </div>

                <div className="mt-4 sm:mt-12">
                    <div className="h-full rounded-lg p-8">
                        <div className="flex items-center justify-center w-full">
                            <label
                                htmlFor="dropzone-file"
                                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition duration-300"
                            >
                                {selectedImages.length === 0 ? (
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg
                                            className="w-12 h-12 mb-4 text-gray-500"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 16"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                            />
                                        </svg>
                                        <p className="mb-2 text-lg text-gray-500">
                                            <span className="font-semibold">Click to upload</span> or drag and drop
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            Any image file (e.g. .jpg, .png)
                                        </p>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <p className="mb-2 text-lg font-semibold text-gray-700">
                                            Images Loaded Successfully
                                        </p>
                                        <p className="text-gray-600">
                                            <strong>{selectedImages.length} image(s) selected</strong>
                                        </p>
                                    </div>
                                )}
                                <input
                                    id="dropzone-file"
                                    type="file"
                                    accept="image/*"
                                    className="hidden"
                                    multiple
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>

                {selectedImages.length > 0 && (
                    <>
                        <div className="text-start my-8 w-full">
                            <h2 className="text-2xl font-bold text-gray-700">Your Selected Images</h2>
                            <p className="text-gray-500">Reorder the images below by dragging and dropping them.</p>
                        </div>
                        <div className="mb-24 max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            {selectedImages.map((image, index) => (
                                <div
                                    key={index}
                                    className="w-full border-2 border-gray-300 rounded-lg overflow-hidden cursor-move"
                                    draggable
                                    onDragStart={() => handleDragStart(index)}
                                    onDragOver={handleDragOver}
                                    onDrop={(event) => handleDrop(event, index)}
                                >
                                    {!imagesLoaded[index] && (
                                        <div className="flex items-center justify-center w-full h-48 bg-gray-100">
                                            <p className="text-gray-500">Loading...</p>
                                        </div>
                                    )}
                                    <img
                                        src={URL.createObjectURL(image)}
                                        alt={`Selected ${index + 1}`}
                                        className={`w-full h-auto object-cover ${imagesLoaded[index] ? '' : 'hidden'}`}
                                        onLoad={() =>
                                            setImagesLoaded((prevState) => ({
                                                ...prevState,
                                                [index]: true,
                                            }))
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </>
                )}


                {selectedImages.length > 0 && (
                    <div className="flex justify-center mt-6">
                        <button
                            onClick={downloadPDF}
                            className="px-6 py-3 w-1/2 rounded-lg bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
                        >
                            Download PDF
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ImagesPDF;
