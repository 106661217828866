import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import invoice from "../../assets/imgs/invoice.gif";
import scanner from "../../assets/imgs/scanner.png";

function Dashboard() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-w-7xl m:mx-auto pb-24 space-y-8 mx-6">
      <div className="max-w-7xl mx-auto mt-32 flex flex-col items-start justify-center">
        <div class="w-full flex items-center justify-center flex-col gap-5 mb-4">
          <span class="bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full">
            Welcome to
          </span>
          <h1 className="font-light  capitalize  md:text-6xl">
            <span className="underline font-semibold bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
              QuickDoc.app
            </span>
          </h1>
          <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center">
            {" "}
            In the world of architecture or organization, structure provides the
            backbone for a purposeful and harmonious existence.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-4 mb-8">
        <div className="h-auto sm:h-32 w-full rounded-xl bg-gradient-to-l from-blue-600 to-violet-500 flex flex-col sm:flex-row items-center justify-between p-6 sm:px-8 md:px-8 lg:px-16 gap-6 sm:gap-8">
          <div className="text-center sm:text-left">
            <h1 className="text-xl sm:text-2xl text-white font-semibold mb-2 sm:mb-1">
              APIs Documentation
            </h1>
            <p className="text-white/90 text-sm sm:text-base max-w-md">
              To integrate our PDF Tools APIs in your legacy system, read its
              documentations
            </p>
          </div>

          <button
            className="bg-white text-blue-600 font-medium rounded px-6 sm:px-8 py-2 hover:bg-blue-50 transition-colors w-full sm:w-auto"
            onClick={() => (window.location.href = "home/apis")}
          >
            Documentation
          </button>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-4 lg:grid-cols-5 lg:gap-8">
        <div class="h-44 rounded-lg">
          <Link
            to="view-pdf"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-file-pdf fa-2xl text-blue-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">PDF Viewer</h1>
                <p className="text-xs">View PDF and Print it ASAP.</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link
            to="split-pdf"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-red"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-file-dashed-line fa-2xl text-red-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">Split PDF</h1>
                <p className="text-xs">Split a PDF into seperate files.</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link
            to="merge-pdf"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-yellow"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-merge fa-2xl text-yellow-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">Merge PDF</h1>
                <p className="text-xs">
                  Merge multple PDFs from smaller chunks{" "}
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link
            to="pdf-to-image"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-solid fa-image fa-2xl text-blue-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">PDF to Image</h1>
                <p className="text-xs">Convert PDF file into image </p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link
            to="compress-pdf"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-green"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i class="fa-duotone fa-solid fa-arrows-minimize fa-2xl text-green-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">Compress PDF</h1>
                <p className="text-xs">Compress PDF File Size</p>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-8">
        <div class="h-44 rounded-lg">
          <Link
            to="json-to-pdf"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-green"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-file-code fa-2xl text-green-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">JSON to PDF</h1>
                <p className="text-sm">Create, Read, Fill Forms</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link
            to="stamp-pdf"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-yellow"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-file-code fa-2xl text-yellow-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">Stamp & Signature</h1>
                <p className="text-sm">Add Stamps & Signature to PDFs</p>
              </div>
            </div>
          </Link>
        </div>


        <div class="h-44 rounded-lg ">
          <Link
            to="watermark"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-file-word fa-2xl text-blue-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">Add Watermark</h1>
                <p className="text-sm">Add Watermarks to you PDFs</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link
            to="ocr"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-scanner-image fa-2xl text-blue-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">OCR</h1>
                <p className="text-sm">Extract Text from PDFs</p>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-4 lg:grid-cols-5 lg:gap-8">
        <div class="h-44 rounded-lg">
          <Link
            to="encrypt-pdf"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-file-shield fa-2xl text-blue-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">Encrypt PDF</h1>
                <p className="text-sm px-2">
                  Encrypt a PDF into meaningless form.
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link
            to="images-pdf"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-red"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-file-image fa-2xl text-red-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">Images to  PDF</h1>
                <p className="text-sm">Convert Images to PDF</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link
            to="decrypt-pdf"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-yellow"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-file-circle-check fa-2xl text-yellow-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">Decrypt PDF</h1>
                <p className="text-sm">See your Encrypted PDF.</p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link
            to="lock-pdf"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-file-lock fa-2xl text-blue-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">Lock PDF</h1>
                <p className="text-sm px-2">
                  Secure a PDF by adding Password over it.
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div class="h-44 rounded-lg">
          <Link
            to="page-deleter"
            className="group flex flex-co items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-yellow"
          >
            <div className="flex flex-col items-center justify-center p-4">
              <div className="rounded-full">
                <i className="fa-duotone fa-trash fa-2xl text-yellow-600"></i>
              </div>
              <div className="mt-6 text-center">
                <h1 className="text-lg font-semibold">PDF Page Deleter</h1>
                <p className="text-sm px-2">Delete Specific Pages from PDF</p>
              </div>
            </div>
          </Link>
        </div>
      </div>

      {/* ======================================================== */}
      <div className="grid grid-cols-1 gap-4 lg:gap-8">
        <div className="h-auto md:h-64 rounded-lg">
          <Link
            to="generate-docx"
            className="group flex flex-col md:flex-row items-center justify-center h-full bg-white border shadow-sm rounded-xl hover:shadow-md transition ripple-blue"
          >
            <div className="flex flex-col md:flex-row items-center justify-between p-4 md:p-8 w-full">
              <div className="mb-4 md:mb-0">
                <img
                  src={invoice}
                  alt="receipt template"
                  className="w-48 md:w-64 h-auto"
                />
              </div>

              <div className="text-center md:text-end space-y-2 md:space-y-4">
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold">
                  Generate Receipts
                </h1>
                <p className="text-sm md:text-base max-w-md md:ml-auto">
                  Generate high quality receipts and documents with tons of
                  variety of templates for your business
                </p>
                <div className="flex flex-wrap items-center justify-center md:justify-end gap-2 md:gap-4">
                  <p className="bg-gray-200 py-1 px-3 text-sm rounded">
                    Invoice
                  </p>
                  <p className="bg-gray-200 py-1 px-3 text-sm rounded">
                    Sales Receipt
                  </p>
                  <p className="bg-gray-200 py-1 px-3 text-sm rounded">
                    Purchase Receipt
                  </p>
                  <p className="bg-gray-200 py-1 px-3 text-sm rounded">Quote</p>
                  <p className="bg-gray-200 py-1 px-3 text-sm rounded">Memo</p>
                  <p className="bg-gray-200 py-1 px-3 text-sm rounded">Bill</p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
