import React, { useState } from "react";
import * as pdfjsLib from "pdfjs-dist";
import { PDFDocument, rgb } from "pdf-lib";
import Draggable from "react-draggable";

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

function StampingPDF() {
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [pdfDetails, setPdfDetails] = useState(null);
  const [pdfPages, setPdfPages] = useState([]);
  const [signature, setSignature] = useState(null);
  const [signaturePosition, setSignaturePosition] = useState({});

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedPdf(file);
      const fileDetails = {
        name: file.name,
        size: (file.size / 1024 / 1024).toFixed(2),
        lastModified: new Date(file.lastModified).toLocaleDateString(),
      };

      const pdfData = await file.arrayBuffer();
      const pdfDocument = await pdfjsLib.getDocument({ data: pdfData }).promise;

      fileDetails.totalPages = pdfDocument.numPages;
      setPdfDetails(fileDetails);

      const pages = [];
      for (let i = 1; i <= pdfDocument.numPages; i++) {
        const page = await pdfDocument.getPage(i);
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise;
        pages.push(canvas.toDataURL("image/png"));
      }
      setPdfPages(pages);
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  const handleSignatureUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const convertedImage = await convertToPng(reader.result, file.type);
        setSignature(convertedImage);
        const initialPositions = {};
        pdfPages.forEach((_, index) => {
          initialPositions[index] = { x: 0, y: 0 };
        });
        setSignaturePosition(initialPositions);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload a valid image file.");
    }
  };

  const convertToPng = (dataUrl, mimeType) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL("image/png"));
      };
      img.onerror = reject;
      img.src = dataUrl;
    });
  };

  const handleDragStop = (e, data, pageIndex) => {
    const updatedPosition = { ...signaturePosition };
    updatedPosition[pageIndex] = { x: data.x, y: data.y };
    setSignaturePosition(updatedPosition);
  };

  const removeSignature = (pageIndex) => {
    const updatedPosition = { ...signaturePosition };
    delete updatedPosition[pageIndex];
    setSignaturePosition(updatedPosition);
  };

  const downloadProcessedPDF = async () => {
    if (!pdfPages.length) {
      alert("Please upload a PDF first.");
      return;
    }

    const pdfDoc = await PDFDocument.create();

    for (let i = 0; i < pdfPages.length; i++) {
      const page = pdfDoc.addPage();
      const { width, height } = page.getSize();

      const pageImageBytes = await fetch(pdfPages[i]).then((res) =>
        res.arrayBuffer()
      );
      const pageImage = await pdfDoc.embedPng(pageImageBytes);

      page.drawImage(pageImage, {
        x: 0,
        y: 0,
        width,
        height,
      });

      if (signature && signaturePosition[i]) {
        const signatureImageBytes = await fetch(signature).then((res) =>
          res.arrayBuffer()
        );
        const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

        const imageWidth = 100;
        const imageHeight =
          (signatureImage.height / signatureImage.width) * imageWidth;

        const { x, y } = signaturePosition[i];

        page.drawImage(signatureImage, {
          x,
          y: height - y - imageHeight,
          width: imageWidth,
          height: imageHeight,
        });
      }
    }

    const modifiedPdfBytes = await pdfDoc.save();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "processed.pdf";
    link.click();
  };

  return (
    <div className="relative overflow-hidden h-full mt-32 mb-48">
      <div className="md:max-w-4xl xl:max-w-6xl mx-auto">
        <div className="text-start">
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
            Stamping & Signature
          </h1>
          <p className="mt-3 text-gray-600">Add Signature and Stamp to your PDF</p>
        </div>

        <div className="mt-7 sm:mt-12">
          <div className="h-full rounded-lg p-8">
            {/* PDF Upload */}
            <div className="flex items-center justify-center w-full space-x-4">
              {/* PDF Upload */}
              <label
                htmlFor="pdf-upload"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition duration-300"
              >
                {!selectedPdf ? (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <p className="mb-2 text-lg text-gray-500">
                      <span className="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-sm text-gray-500">PDF file (up to 40MB)</p>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <p className="mb-2 text-lg font-semibold text-gray-700">
                      PDF Loaded Successfully
                    </p>
                    <p className="text-gray-600">
                      <strong>File Name:</strong>{" "}
                      <span className="text-blue-600">{pdfDetails?.name}</span>
                    </p>
                  </div>
                )}
                <input
                  id="pdf-upload"
                  type="file"
                  accept=".pdf"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>

              {/* Image Upload */}
              <label
                htmlFor="image-upload"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition duration-300"
              >
                {!signature ? (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <p className="mb-2 text-lg text-gray-500">
                      <span className="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-sm text-gray-500">Image file (e.g., PNG, JPG)</p>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <p className="mb-2 text-lg font-semibold text-gray-700">
                      Image Loaded Successfully
                    </p>
                    <p className="text-gray-600">
                      <strong>Image:</strong>{" "}
                      <span className="text-blue-600">Signature Uploaded</span>
                    </p>
                  </div>
                )}
                <input
                  id="image-upload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleSignatureUpload}
                />
              </label>

            </div>
          </div>

          {/* Display the converted PDF pages */}
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
            {pdfPages.map((page, index) => (
              <div
                key={index}
                className="relative border border-gray-300 rounded-lg p-4 bg-white shadow"
              >
                <img
                  src={page}
                  alt={`Page ${index + 1}`}
                  className="w-full rounded-lg"
                />
                {signature && signaturePosition[index] && (
                  <Draggable
                    position={{
                      x: signaturePosition[index]?.x || 0,
                      y: signaturePosition[index]?.y || 0,
                    }}
                    onStop={(e, data) => handleDragStop(e, data, index)}
                  >
                    <div className="absolute top-0 left-0 cursor-move flex items-center">
                      <img
                        src={signature}
                        alt="Signature"
                        style={{ width: "100px" }}
                      />
                      <button
                        className="bg-red-500 text-white text-sm rounded-full p-1 ml-2"
                        onClick={() => removeSignature(index)}
                      >
                        ×
                      </button>
                    </div>
                  </Draggable>
                )}
              </div>
            ))}
          </div>

          {/* Download Button */}
          <div className="mt-8 text-center">
            <button
              onClick={downloadProcessedPDF}
              className="px-6 py-3 w-1/2 rounded-lg bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200"
            >
              Download Processed PDF
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StampingPDF;
